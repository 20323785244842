
import state from "./moduleUnitDeliveryStatusState.js"
import mutations from "./moduleUnitDeliveryStatusMutations.js"
import getters from "./moduleUnitDeliveryStatusGetters.js"
import actions from './moduleUnitDeliveryStatusActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
