export default {
  ADD_ITEM(state, item) {
    state.UnitDeliveryStatus.unshift(item);
  },
  SET_UnitDeliveryStatus(state,  UnitDeliveryStatus) {
    state.UnitDeliveryStatus =  UnitDeliveryStatus;
  },
  UPDATE_UnitDeliveryStatus(state,  UnitDeliveryStatus) {
    const  UnitDeliveryStatusIndex = state.UnitDeliveryStatus.findIndex(p => p.ID ==  UnitDeliveryStatus.ID);
    if ( UnitDeliveryStatusIndex != -1) {
      Object.assign(state. UnitDeliveryStatus[ UnitDeliveryStatusIndex],  UnitDeliveryStatus);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.UnitDeliveryStatus.findIndex(p => p.ID == itemId);
    state.UnitDeliveryStatus.splice(ItemIndex, 1);
  }
};

