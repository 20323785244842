import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("API/Lookup/AddUintDeliveryStatus", item)
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.data.ID })
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("API/Lookup/GetAllUintDeliveryStatus")
        .then(response => {
          resolve(response);
          commit("SET_UnitDeliveryStatus", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateItem(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put("API/Lookup/UpdateUintDeliveryStatus", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID(context, itemid) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/Lookup/GetConstructionUnitStatusById?Id=" + itemid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteObject({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("API/Lookup/DeleteUintDeliveryStatus?Id=" + item.id)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
